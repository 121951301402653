import { Table } from "react-bootstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCircleCheck,
  faDownload,
  faHourglass,
  faTrash,
  faCircleXmark,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import { styles } from "../HRTableListStyle";

import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";

const TableReviewRecommendList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    reviewRecommendLists,
    currentRevRecPage,
    totalCurrentRevRecPage,
    searchReviewRecommendLists,
    searchKeyWordReviewRecommendLists,
  } = useSelector((store) => store.HRTableList);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentRevRecPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const handleClick = (_id) => {
    navigate(`/HRViewForm/${_id}`);
    // console.log(_id);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/basic/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadApplicationForm = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/full/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/full/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Table>
      <thead style={styles.TableHeader}>
        <tr>
          <th style={styles.ThText}>No</th>
          <th style={styles.ThText}>Date Applied</th>
          <th style={styles.ThText}>Name</th>
          <th style={styles.ThText}>Position</th>
          <th style={styles.ThText}>NRIC</th>
          <th style={styles.ThText}>Circulate</th>
          <th style={styles.ThIcon}>
            <FontAwesomeIcon icon={faBoltLightning} />
          </th>
        </tr>
      </thead>
      <tbody>
        {reviewRecommendLists.map(
          (
            { _id, sectionOne, date2, isUpdatedByCandidate, circulations },
            i
          ) => (
            <tr key={i}>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {i + 1 + indexOfFirstRecord}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {date2}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.name.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.position_applied?.label ||
                  sectionOne?.position_applied.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.identification_certification}
              </td>
              <td style={styles.TdText}>
                <ol>
                  {circulations.map((circulation, j) => (
                    <li key={`${i}-${j}`} style={{ textAlign: "left" }}>
                      {circulation?.svName.toUpperCase()}&nbsp;
                      {circulation.circulationStatus == 400 && (
                        <FontAwesomeIcon
                          icon={faHourglass}
                          color="#5E8ABB"
                          title="Pending Supervisor Approval"
                        />
                      )}
                      {circulation.circulationStatus == -400 && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          color="#5E8ABB"
                          title="Circulation Deleted"
                        />
                      )}
                      {circulation.circulationStatus == 500 && (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          color="#5E8ABB"
                          title="Approved by Supervisor"
                        />
                      )}
                      {circulation.circulationStatus == 120 && (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          color="#5E8ABB"
                          title="Rejected by Supervisor"
                        />
                      )}
                      {circulation.circulationStatus == 130 && (
                        <FontAwesomeIcon
                          icon={faUserSlash}
                          color="#5E8ABB"
                          title="Interview Cancel"
                        />
                      )}
                    </li>
                  ))}
                </ol>
              </td>
              <td className="text-nowrap" style={{ textAlign: "left" }}>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display: isUpdatedByCandidate === 1 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadApplicationForm(_id)}
                    icon={faDownload}
                    title="Print Form"
                    color="#E1AD01"
                  />
                </span>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display: isUpdatedByCandidate != 1 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadBiodata(_id)}
                    icon={faDownload}
                    title="Print Biodata"
                    color="blue"
                  />
                </span>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};
export default TableReviewRecommendList;
