import { Table } from "react-bootstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faBoltLightning,
  faCircleCheck,
  faDownload,
  faCircleXmark,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import { styles } from "../HRTableListStyle";

import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";

const TableInternshipList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    internshipLists,
    currentInternshipPage,
    totalCurrentInternshipPage,
    searchInternshipLists,
    searchKeyWordInternshipLists,
  } = useSelector((store) => store.HRTableList);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentInternshipPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const handleClick = (_id) => {
    navigate(`/HRViewForm/${_id}`);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/internship/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/internship/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadApplicationForm = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/internship/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/internship/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Table>
      <thead style={styles.TableHeader}>
        <tr>
          <th style={styles.ThText}>No</th>
          <th style={styles.ThText}>Date Applied</th>
          <th style={styles.ThText}>Name</th>
          <th style={styles.ThText}>NRIC</th>
          <th style={styles.ThText}>
            <FontAwesomeIcon icon={faGraduationCap} />
          </th>
          <th style={styles.ThText}>Location</th>
          <th style={styles.ThText}>Circulate</th>
          <th style={styles.ThText}>Placement</th>
          <th style={styles.ThIcon}>
            <FontAwesomeIcon icon={faBoltLightning} />
          </th>
        </tr>
      </thead>
      <tbody>
        {internshipLists.map(
          (
            {
              _id,
              date2,
              sectionOne,
              hrRemark,
              isUpdatedByCandidate,
              status,
              circulations,
            },
            i
          ) => (
            <tr
              key={i}
              style={{
                backgroundColor:
                  // status == 10 || status == 0 ? "#DDF4FC" : "#ffffff",
                  status === 10 || status === 0
                    ? "#DDF4FC"
                    : status === -10
                    ? "#fdb0aa"
                    : "#ffffff",
              }}
            >
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {i + 1 + indexOfFirstRecord}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {date2}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.name.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.identification_certification}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "SPM" && "SPM"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value ==
                    "STPM/Matriculation/Foundation/STAM" && "STPM"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "Certificates" &&
                    "CERT"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "Diploma" &&
                    "DIP"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "Degree" &&
                    "DEG"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "Master" &&
                    "MAS"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "PHD" && "PHD"}
                </a>

                <a
                  title={
                    sectionOne?.studyField?.value == "Other"
                      ? sectionOne?.studyField_other
                      : sectionOne?.studyField?.label
                  }
                >
                  {sectionOne?.highest_qualification?.value == "Others" &&
                    "OTH"}
                </a>
                {/* {sectionOne?.highest_qualification?.value == "SPM" && "SPM"} */}
                {/* {sectionOne?.highest_qualification?.value == "STPM/Matriculation/Foundation/STAM" && "STPM"} */}
                {/* {sectionOne?.highest_qualification?.value == "Certificates" && "CERT"} */}
                {/* {sectionOne?.highest_qualification?.value == "Diploma" && "DIP"} */}
                {/* {sectionOne?.highest_qualification?.value == "Degree" && "DEG"} */}
                {/* {sectionOne?.highest_qualification?.value == "Master" && "MAS"} */}
                {/* {sectionOne?.highest_qualification?.value == "PHD" && "PHD"} */}
                {/* {sectionOne?.highest_qualification?.value == "Others" && "OTH"} */}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {sectionOne?.current_location?.label.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {circulations?.map((circulation, j) => (
                  <li
                    key={`${i}-${j}`}
                    // style={{
                    //   textAlign: "left",
                    //   color:
                    //     circulation?.circulationStatus == 500
                    //     ? "green"
                    //     : circulation?.circulationStatus == 120
                    //     ? "red"
                    //     : "black"
                    // }}
                  >
                    {circulation?.svName}&nbsp;
                    {circulation.circulationStatus == 500 && (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        color="#5E8ABB"
                        title="Approved by Supervisor"
                      />
                    )}
                    {circulation.circulationStatus == 120 && (
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        color="#5E8ABB"
                        title="Rejected by Supervisor"
                      />
                    )}
                    {circulation.circulationStatus == 130 && (
                      <FontAwesomeIcon
                        icon={faUserSlash}
                        color="#5E8ABB"
                        title="Interview Cancel"
                      />
                    )}
                  </li>
                ))}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {circulations?.map((circulation, j) => (
                  <a key={`${i}-${j}`} style={{ textAlign: "left" }}>
                    {circulation?.assignDeptProject}
                  </a>
                ))}
                {/* {status == -10 && "Rejected"} */}
              </td>
              <td className="text-nowrap" style={{ textAlign: "left" }}>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display: isUpdatedByCandidate === 1 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadApplicationForm(_id)}
                    icon={faDownload}
                    title="Print Form"
                    color="#E1AD01"
                  />
                </span>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display: isUpdatedByCandidate != 1 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadBiodata(_id)}
                    icon={faDownload}
                    title="Print Biodata"
                    color="blue"
                  />
                </span>
                {hrRemark && (
                  <span style={{ marginLeft: "0.5rem" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="blue"
                      title="HR Remark Exist"
                    />
                  </span>
                )}
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};
export default TableInternshipList;
