import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Card, Image, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faDownload } from "@fortawesome/free-solid-svg-icons";

import "../SVTableList.css";

import { getAssessmentForm } from "../../../4.Store/AssessmentSlice";

const SVCardAssessmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    assessmentBySvIdLists,
    currentSVAssessmentPage,
    totalCurrentSVAssessmentPage,
    searchAssessmentBySvIdLists,
    searchKeyWordAssessmentBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  const handleClick = (_id) => {
    navigate(`/AssessmentForm/${_id}`);
    // console.log(_id);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getAssessmentForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadFullForm = (_id) => {
    dispatch(getAssessmentForm(_id)).then((data) => {
      // console.log(data);
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/full/${data?.payload?.jobAppId}/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Container className="CardContainer" fluid>
      {searchKeyWordAssessmentBySvIdLists ? (
        <>
          {searchAssessmentBySvIdLists.map(
            (
              { _id, jobapplication, interviewDate, interviewTime, approval },
              i
            ) => (
              <Card className="CardStyle" onClick={() => handleClick(_id)}>
                <Card.Header className="CardHeader">
                  <h5 className="NameHeader">
                    {jobapplication?.sectionOne?.name}
                  </h5>
                </Card.Header>
                <Card.Body className="CardBody">
                  <Row>
                    <Col xs={4} sm={4} md={4}>
                      <Image
                        src={jobapplication?.sectionOne?.image}
                        className="ProfileImage"
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8}>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h5 className="Text">
                            Interview Date:&nbsp;{interviewDate}
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h5 className="Text">
                            Interview Time:&nbsp;
                            {interviewTime?.label || interviewTime}
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <hr className="HorizontalLine" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="PositionStyle">
                            {jobapplication?.sectionOne?.position_applied
                              ?.label ||
                              jobapplication?.sectionOne?.position_applied}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            RM&nbsp;
                            {jobapplication?.sectionOne?.expected_salary ||
                              "N/A"}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {approval?.interviewer_recommendation || "N/A"}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="CardFooter d-flex justify-content-end">
                  {jobapplication?.isUpdatedByCandidate === 1 ? (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadFullForm(_id, jobapplication);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowDown} color="#E1AD01" />
                      &nbsp; Download PDF
                    </Button>
                  ) : (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadBiodata(_id, jobapplication);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} color="white" />
                      &nbsp; Download PDF
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            )
          )}
        </>
      ) : (
        <>
          {assessmentBySvIdLists.map(
            (
              { _id, jobapplication, interviewDate, interviewTime, approval },
              i
            ) => (
              <Card className="CardStyle" onClick={() => handleClick(_id)}>
                <Card.Header className="CardHeader">
                  <h5 className="NameHeader">
                    {jobapplication?.sectionOne?.name}
                  </h5>
                </Card.Header>
                <Card.Body className="CardBody">
                  <Row>
                    <Col xs={4} sm={4} md={4}>
                      <Image
                        src={jobapplication?.sectionOne?.image}
                        className="ProfileImage"
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8}>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h5 className="Text">
                            Interview Date:&nbsp;{interviewDate}
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h5 className="Text">
                            Interview Time:&nbsp;
                            {interviewTime?.label || interviewTime}
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <hr className="HorizontalLine" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="PositionStyle">
                            {jobapplication?.sectionOne?.position_applied
                              ?.label ||
                              jobapplication?.sectionOne?.position_applied}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            RM&nbsp;
                            {jobapplication?.sectionOne?.expected_salary ||
                              "N/A"}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {approval?.interviewer_recommendation || "N/A"}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="CardFooter d-flex justify-content-end">
                  {jobapplication?.isUpdatedByCandidate === 1 ? (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadFullForm(_id, jobapplication);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowDown} color="#E1AD01" />
                      &nbsp; Download PDF
                    </Button>
                  ) : (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadBiodata(_id, jobapplication);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} color="white" />
                      &nbsp; Download PDF
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            )
          )}
        </>
      )}
    </Container>
  );
};
export default SVCardAssessmentList;
