import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Card, Image, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faDownload } from "@fortawesome/free-solid-svg-icons";

import "../SVTableList.css";

import { getCirculationByCirculationId } from "../../../4.Store/CirculationSlice";

const SVCardApplicationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    circulationBySvIdLists,
    currentSVApplicationPage,
    totalCurrentSVApplicationPage,
    searchCirculationBySvIdLists,
    searchKeyWordCirculationBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  const handleClick = (_id) => {
    navigate(`/SVViewForm/${_id}`);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadFullForm = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      const pdfUrl = `https://jobapp2.hslnet.my/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Container className="CardContainer" fluid>
      {searchKeyWordCirculationBySvIdLists ? (
        <>
          {searchCirculationBySvIdLists.map(
            ({ _id, jobapplication, circulationStatus }, i) => (
              <Card className="CardStyle" onClick={() => handleClick(_id)}>
                <Card.Header className="CardHeader">
                  <h5 className="NameHeader">
                    {jobapplication?.sectionOne?.name}
                  </h5>
                </Card.Header>
                <Card.Body className="CardBody">
                  <Row>
                    <Col xs={4} sm={4} md={4}>
                      <Image
                        src={jobapplication?.sectionOne?.image}
                        className="ProfileImage"
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8}>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h4 className="PositionStyle">
                            {jobapplication?.sectionOne?.internship == "yes" ? (
                              "INTERNSHIP"
                            ) : (
                              <>
                                {jobapplication?.sectionOne?.position_applied
                                  ?.label ||
                                  jobapplication?.sectionOne?.position_applied}
                              </>
                            )}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} sm={6} md={6}>
                          <h5 className="Text">
                            RM&nbsp;
                            {jobapplication?.sectionOne?.expected_salary ||
                              "N/A"}
                          </h5>
                        </Col>
                        <Col xs={6} sm={6} md={6}>
                          <p className="DateStyle">{jobapplication?.date}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <hr className="HorizontalLine" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {
                              jobapplication?.sectionOne?.highest_qualification
                                ?.label
                            }
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {
                              jobapplication?.sectionOne?.current_location
                                ?.label
                            }
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {(jobapplication?.sectionOne?.fresh_graduate
                              ?.value == "Yes" && (
                              <span>FRESH GRADUATE</span>
                            )) ||
                              (jobapplication?.sectionOne?.fresh_graduate
                                ?.value == "No" &&
                                jobapplication?.sectionOne?.fresh_graduate_no +
                                  " " +
                                  "YEAR(S) EXPERIENCE") ||
                              "N/A"}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} sm={6} md={6}>
                          <h6 className="Text">
                            {(circulationStatus == 400 && (
                              <span>PENDING</span>
                            )) ||
                              (circulationStatus == 500 && (
                                <span>ACCEPTED</span>
                              )) ||
                              (circulationStatus == 120 && (
                                <span>REJECTED</span>
                              ))}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="CardFooter d-flex justify-content-end">
                  {jobapplication?.isUpdatedByCandidate === 1 ? (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadFullForm(_id);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowDown} color="black" />
                      &nbsp; Download PDF
                    </Button>
                  ) : (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadBiodata(_id);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} color="black" />
                      &nbsp; Download PDF
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            )
          )}
        </>
      ) : (
        <>
          {circulationBySvIdLists.map(
            ({ _id, jobapplication, circulationStatus }, i) => (
              <Card className="CardStyle" onClick={() => handleClick(_id)}>
                <Card.Header className="CardHeader">
                  <h5 className="NameHeader">
                    {jobapplication?.sectionOne?.name}
                  </h5>
                </Card.Header>
                <Card.Body className="CardBody">
                  <Row>
                    <Col xs={4} sm={4} md={4}>
                      <Image
                        src={jobapplication?.sectionOne?.image}
                        className="ProfileImage"
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8}>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h4 className="PositionStyle">
                            {jobapplication?.sectionOne?.internship == "yes" ? (
                              "INTERNSHIP"
                            ) : (
                              <>
                                {jobapplication?.sectionOne?.position_applied
                                  ?.label ||
                                  jobapplication?.sectionOne?.position_applied}
                              </>
                            )}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} sm={6} md={6}>
                          <h5 className="Text">
                            RM&nbsp;
                            {jobapplication?.sectionOne?.expected_salary ||
                              "N/A"}
                          </h5>
                        </Col>
                        <Col xs={6} sm={6} md={6}>
                          <p className="DateStyle">{jobapplication?.date}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <hr className="HorizontalLine" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {
                              jobapplication?.sectionOne?.highest_qualification
                                ?.label
                            }
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {
                              jobapplication?.sectionOne?.current_location
                                ?.label
                            }
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <h6 className="Text">
                            {(jobapplication?.sectionOne?.fresh_graduate
                              ?.value == "Yes" && (
                              <span>FRESH GRADUATE</span>
                            )) ||
                              (jobapplication?.sectionOne?.fresh_graduate
                                ?.value == "No" &&
                                jobapplication?.sectionOne?.fresh_graduate_no +
                                  " " +
                                  "YEAR(S) EXPERIENCE") ||
                              "N/A"}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} sm={6} md={6}>
                          <h6 className="Text">
                            {(circulationStatus == 400 && (
                              <span>PENDING</span>
                            )) ||
                              (circulationStatus == 500 && (
                                <span>ACCEPTED</span>
                              )) ||
                              (circulationStatus == 120 && (
                                <span>REJECTED</span>
                              ))}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="CardFooter d-flex justify-content-end">
                  {jobapplication?.isUpdatedByCandidate === 1 ? (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadFullForm(_id);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowDown} color="#E1AD01" />
                      &nbsp; Download PDF
                    </Button>
                  ) : (
                    <Button
                      className="CardButton"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event from propagating to the parent elements
                        handleDownloadBiodata(_id);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} color="white" />
                      &nbsp; Download PDF
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            )
          )}
        </>
      )}
    </Container>
  );
};
export default SVCardApplicationList;
